function showTopMenu() {
    var el = document.querySelector('.person-desktop');
    if (el) {
        el.addEventListener('mouseover', function () {
            this.closest('.top-menu').classList.add('top-menu-active');
        });

        el.addEventListener('mouseout', function () {
            this.closest('.top-menu').classList.remove('top-menu-active');
        });
    }

    var secondEl = document.querySelector('.person-mobile');

    if (secondEl) {
        secondEl.addEventListener('click', function () {
            this.closest('.top-menu').classList.toggle('top-menu-active');
        });
    }

    var topMenu = document.querySelector('.top-drop-menu');
    if (topMenu) {
        topMenu.addEventListener('mouseover', function () {
            this.closest('.top-menu').classList.add('top-menu-active');
        });

        topMenu.addEventListener('mouseout', function () {
            var windowWith = window.innerWidth;
            if (windowWith > 1091) {
                this.closest('.top-menu').classList.remove('top-menu-active');
            }
        });
    }
}

function checkNavigationPage() {
    var navigationEl = document.querySelector('.head-divider');
    if (navigationEl) {
        var slider = document.querySelector('.section-slider');
        if (slider) {
            slider.style.marginTop = -97 + 'px';
        }
    }
}


function maineDropDown() {
    var contentDrop = document.querySelector('.dropdownBtn');
    var buttonShow = document.querySelector('.selected-item');
    if (contentDrop && buttonShow) {

        var buttonsShow = document.querySelectorAll('.selected-item');

        buttonsShow.forEach(item => {
            item.addEventListener('click', function () {
                // document.querySelectorAll('.dropdownBtn').forEach(item => {
                //     item.classList.remove('dropdownBtn-active');
                // });
                this.closest('.dropdownBtn').classList.toggle('dropdownBtn-active');
            });
        });

        document.querySelectorAll('.drItem').forEach(item => {
            item.addEventListener('click', function () {
                this.closest('.dropdownBtn').querySelector('.selected-item').innerText = this.innerText;
                this.closest('.dropdownBtn').querySelector('.selected-item').value = this.innerText;
            });

        });

        document.addEventListener('click', function (e) {
            if (!e.target.classList.contains('selected-item')) {
                document.querySelectorAll('.dropdownBtn').forEach(item => {
                    item.classList.remove('dropdownBtn-active');
                })
            }
        })
    }
}

function showOrderCallForm() {
    var btn = document.getElementById('order-call-btn');
    var orderCallForm = document.getElementById('order-call-form');
    var closeBtn = document.getElementById('close-form');
    if (btn)
        btn.addEventListener('click', function () {
            orderCallForm.classList.toggle('active-section-order');
        });
    if (closeBtn)
        closeBtn.addEventListener('click', function () {
            orderCallForm.classList.toggle('active-section-order');
        })
}

function dataFlatpickr(type) {
    return () => {
        var planDatePickr;
        if (type === 'birdtday3') {
            planDatePickr = flatpickr("." + type, {
                enableTime: false,
                dateFormat: "Y-m-d",
                defaultDate: false,
                maxDate: new Date().setFullYear(new Date().getFullYear() - 21)
            });
            planDatePickr.toggle()
        } else {
            planDatePickr = flatpickr("." + type);
            planDatePickr.toggle()
        }
    }
}

$(document).ready(function () {
    showOrderCallForm();
    maineDropDown();
    checkNavigationPage();
    showTopMenu();

    $(".moneyMask").inputmask({alias: "currency", min: 0, prefix: '', textAlign: 'left'});
    if (document.getElementById('date_toggle_phisical')) {
        document.getElementById('date_toggle_phisical').addEventListener('click', dataFlatpickr('birdtday3'))
    }

//     console.log(
//         `
//  developed by
//  _______  _______  _______  ___      _______  _______  __   __   _______  _______  _______  _______  _     _  _______  ______    _______
// |       ||       ||       ||   |    |  _    ||       ||  |_|  | |       ||       ||       ||       || | _ | ||   _   ||    _ |  |       |
// |_     _||   _   ||   _   ||   |    | |_|   ||   _   ||       | |  _____||   _   ||    ___||_     _|| || || ||  |_|  ||   | ||  |    ___|
//   |   |  |  | |  ||  | |  ||   |    |       ||  | |  ||       | | |_____ |  | |  ||   |___   |   |  |       ||       ||   |_||_ |   |___
//   |   |  |  |_|  ||  |_|  ||   |___ |  _   | |  |_|  | |     |  |_____  ||  |_|  ||    ___|  |   |  |       ||       ||    __  ||    ___|
//   |   |  |       ||       ||       || |_|   ||       ||   _   |  _____| ||       ||   |      |   |  |   _   ||   _   ||   |  | ||   |___
//   |___|  |_______||_______||_______||_______||_______||__| |__| |_______||_______||___|      |___|  |__| |__||__| |__||___|  |_||_______|
//
//   https://toolbox.am/`
//     );

    //nav Zindex cor

    $('.main-nav-item').hover(function () {
        if ($(this).find('.main-nav-drop').length > 0) {
            $('.nav-content-wrap').toggleClass('s-cor')
        }
    });


    $('.pos-credit-type').click(function () {
        $('#form_questionUserType').val($(this).attr('data-type'))
    });

    //preloader
    $('.main-preloader').fadeOut();

    //menu init
    $('.dropdown-trigger').dropdown({
        hover: false,
        coverTrigger: false,
        constrainWidth: false
    });

    $('.tel-type-wrapper').find('.dropdown-trigger').dropdown({
        alignment: 'right'
    });


    //Ask question control
    askMeTab();
    //Ask question control

    //Custom-collapseInit
    customCollapseInit();
    //Custom-collapseInit


    //modal-init
    $('#callmodal,#searchModal, #orderModal, #credModal, #agentQuestion, #pillshelptab, #securityModal, #becomePartner').modal({
        endingTop: '20%',
        onOpenStart: function () {
            $('.page-wrapper').addClass('overflowH')
        },
        onCloseStart: function () {
            $('.page-wrapper').removeClass('overflowH')
        }
    });
    $('#onlineRequestHouse').modal({
        endingTop: '4%',
    });

    $('#isAgree').change(function () {
        if ($(this).is(":checked")) {
            $('#form_isAgree').val(1)
        } else {
            $('#form_isAgree').val(0)
        }
    });


    $('input[type=radio][name=answerType]').change(function () {
        $('#form_answerType').val(this.value)
    });

    $clearFormFields = $('#clear-fields')
    $clearFormFields.click(function () {
        $parentForm = $(this).parents('form')

        $parentForm.find('input').each(function () {

            /*** FIX FOR MATERIAL DROPDOWNS ***/
            if ($(this).hasClass('dropdown-trigger')) {
                var firstValue = $(this).next().find('li').eq(0).find('span').text()
                $(this).val(firstValue)
                return true;
            }

            $(this).val('')
        })
    });


    //collapse-init
    $('.collapsible').collapsible();

    //sidebar init
    $('.side-bar').click(function () {
        $('.sidenav-wrapper').slideToggle(200);
        $('.page-wrapper').toggleClass('overflowH');

    });

    $('#nav-icon1').click(function () {
        $(this).toggleClass('open');
    });

    //active nav
    $(document).scroll(function () {

        if ($(window).scrollTop() > 0) {
            $('.left-menu').addClass('z-depth-1')
        } else {
            $('.left-menu').removeClass('z-depth-1')
        }

        liveChat()

    });

    //select init
    $('select').formSelect({
        dropdownOptions: {
            coverTrigger: false,
            alignment: 'bottom',
            onOpenStart: function () {
                this.dropdownEl.classList.add('pos-bottom');
            }
        }
    });

    //tab init
    $('.tabs').tabs({
        swipeable: true
    });

    var houseItems = document.querySelectorAll('.house-item');

    if (houseItems) {
        houseItems.forEach(item => {
            item.onclick = function () {
                var curImg = this.children[0].src.replace('/thumb/', '/');
                document.querySelector('.house-preview-img').src = curImg
            }
        })
    }

    //advanced view slider
    // $('.house-item').click(function () {
    //     var mainImg = $('.house-preview-img');
    //     var curImg = $(this).find('img').attr('src');
    //     var replacedImg = curImg.replace('/thumb/','/');
    //     mainImg.attr('src', replacedImg)
    // });


    function getNumberVal(val) {
        val = val ? Number(val.split(',').join('')) : '';
        return val;
    }

    function chageAbountSlider() {
        //slider ini
        var advancedPriceSlider = $('#advancedPriceSlider');
        let min_amount = getNumberVal($('.advanced-amount-min').val());
        let max_amount = getNumberVal($('.advanced-amount-max').val());
        advancedPriceSlider.slider({
            range: true,
            min: 0,
            max: $('#max-price-number').val(),
            values: [min_amount, (max_amount > 0) ? max_amount : $('#max-price-number').val()],

            slide: function (event, ui) {
                $(".advanced-amount-min").val(numberWithCommas(ui.values[0]));
                $(".advanced-amount-max").val(numberWithCommas(ui.values[1]));
            }
        });

        $(".advanced-amount-min").val(numberWithCommas(advancedPriceSlider.slider("values", 0)));
        $(".advanced-amount-max").val(numberWithCommas(advancedPriceSlider.slider("values", 1)));

        $('.advanced-amount-min').keyup(function () {
            advancedPriceSlider.slider("values", 0, $(this).val());
        });
        $('.advanced-amount-max').keyup(function () {
            advancedPriceSlider.slider("values", 1, $(this).val());
        });

        var advancedSqr = $('#advancedSqr');

        let advanced_sqr_min = getNumberVal($('.advanced-sqr-min').val());
        let advanced_sqr_max = getNumberVal($('.advanced-sqr-max').val());

        advancedSqr.slider({
            range: true,
            min: 0,
            max: $('#max-area-number').val(),
            values: [advanced_sqr_min, (advanced_sqr_max > 0) ? advanced_sqr_max : $('#max-area-number').val()],
            slide: function (event, ui) {
                $(".advanced-sqr-min").val(numberWithCommas(ui.values[0]));
                $(".advanced-sqr-max").val(numberWithCommas(ui.values[1]));
            }
        });
        $(".advanced-sqr-min").val(numberWithCommas(advancedSqr.slider("values", 0)));
        $(".advanced-sqr-max").val(numberWithCommas(advancedSqr.slider("values", 1)));

        $('.advanced-sqr-min').keyup(function () {
            advancedSqr.slider("values", 0, $(this).val());
        });
        $('.advanced-sqr-max').keyup(function () {
            advancedSqr.slider("values", 1, $(this).val());
        });
    }

    chageAbountSlider();
    let currencyType = document.querySelector('select#currencyType');
    if (currencyType) {
        currencyType.onchange = function () {
            changePriceByCurrency(this)
        };

        function changePriceByCurrency(This) {
            let maxVal = document.querySelector('.advanced-amount-max').getAttribute('max');
            if (This.value === 'USD') {
                // let usd = document.getElementById('pills-home').querySelectorAll('tr')[1].querySelectorAll('td')[1].innerHTML;
                let usd = parseFloat(document.getElementById('currency').value)
                let amd = Number(maxVal);
                let currency = Number(usd);
                let sum = amd / currency;
                let x = Math.ceil(sum);
                document.getElementById('max-price-number').value = x;
                document.querySelector('.advanced-amount-max').value = x;
            } else {
                document.getElementById('max-price-number').value = maxVal;
                document.querySelector('.advanced-amount-max').value = maxVal;
            }
            document.querySelector('.advanced-amount-min').value = 0;
            chageAbountSlider()
        }

        function changeMax(This) {
            let maxVal = document.querySelector('.advanced-amount-max').getAttribute('max');
            if (This.value === 'USD') {
                let usd = document.getElementById('pills-home').querySelectorAll('tr')[1].querySelectorAll('td')[1].innerHTML;
                let amd = Number(maxVal);
                let currency = Number(usd);
                let sum = amd / currency;
                let x = Math.ceil(sum);
                document.getElementById('max-price-number').value = x;
            }
            chageAbountSlider()
        }

        changeMax(currencyType)
    }

    var productionDate = $('#productionDate');
    var productionDateStart = $(".productionDateStart");
    var productionDateEnd = $(".productionDateEnd");
    var minYear = Number($(productionDateStart).attr('min'));
    var maxYear = Number($(productionDateEnd).attr('max'));
    let minYearVal = $(productionDateStart).val() ? Number($(productionDateStart).val()) : minYear;
    let maxYearVal = $(productionDateEnd).val() ? Number($(productionDateEnd).val()) : maxYear;
    productionDate.slider({
        range: true,
        min: minYear,
        max: maxYear,
        values: [minYearVal, maxYearVal],
        slide: function (event, ui) {
            $(productionDateStart).val(ui.values[0]);
            $(productionDateEnd).val(ui.values[1]);
        }
    });

    $(productionDateStart).val(productionDate.slider("values", 0));
    $(productionDateEnd).val(productionDate.slider("values", 1));

    $(productionDateStart).keyup(function () {
        productionDate.slider("values", 0, $(this).val());
    });
    $(productionDateEnd).keyup(function () {
        productionDate.slider("values", 1, $(this).val());
    });


    var advancedDist = $('#advancedDist');
    let advanced_dist_val = getNumberVal($('.advanced-dist-val').val());
    advancedDist.slider({
        min: 0,
        max: $('#max-distance-number').val(),
        value: (advanced_dist_val > 0) ? advanced_dist_val : $('#max-distance-number').val(),
        slide: function (event, ui) {
            $(".advanced-dist-val").val(numberWithCommas(ui.value));
        }
    });

    $('.advanced-dist-val').val(numberWithCommas(advancedDist.slider('value')));

    $('.advanced-dist-val').keyup(function () {
        advancedDist.slider("value", $(this).val());
    });


    //////////credit terms///////////
    if ($('.active-term').length) {
        var id = $('.active-term').attr('id');
        var id = parseInt(id.slice(4));
        $currentCategory = $('p[data-id="' + id + '"]');
        $currentCategory.parents('.category-item').addClass('active');
    }

    //Start of owl init
    //suggest-item
    var suggestOwl = $('.suggest-item-owl');

    suggestOwl.owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: true,
        items: 1
    });


    var owlmobile = $('.owl-mobile');

    owlmobile.owlCarousel({
        loop: true,
        margin: 30,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            992: {
                items: 3
            }

        }
    });

    //home slider toggle
    var headSlider = $('.head-owl-slide');
    headSlider.owlCarousel({
        loop: true,
        margin: 0,
        nav: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        animateOut: 'fadeOut',
        dots: true,
        autoplay: false,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },

            676: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    });

    var bannerOwl = $('.banner-content-owl');
    bannerOwl.owlCarousel({
        loop: false,
        nav: false,
        animateOut: 'fadeOut',
        dots: true,
        autoplay: false,
        items: 1,
        margin: 0
    });


    var $owl = $('.advanced-house-carousel');

    $owl.children().each(function (index) {
        $(this).attr('data-position', index); // NB: .attr() instead of .data()
    });

    $owl.owlCarousel({
        center: true,
        items: 3,
        loop: true,
        margin: 15,
        autoplay: false,
        dots: false,
        responsive: {
            0: {
                items: 3
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    });

    $(document).on('click', '.owl-item>div', function () {
        $owl.trigger('to.owl.carousel', $(this).data('position'));
    });


    /*   $('.advanced-house-carousel').owlCarousel({
           loop: true,
           margin: 15,
           autoplay: false,
           dots: false,
           responsive: {
               0: {
                   items: 3
               },
               600: {
                   items: 3
               },
               1000: {
                   items: 3
               }
           }
       });*/

    $('.suggested-house-carousel').owlCarousel({
        loop: true,
        margin: 15,
        autoplay: true,
        dots: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 3
            }
        }
    });

    function topServiceOwl() {
        var topService = $('.top-service-owl');
        topService.owlCarousel({
            center: true,
            items: 2,
            loop: true,
            dots: true,
            margin: 0,
        });
    }

    function secSericeOwl() {
        var topService = $('.second-service-owl');
        topService.owlCarousel({
            center: true,
            items: 2,
            loop: true,
            dots: true,
            margin: 0,
        });
    }

    window.onresize = doALoadOfStuff;

    function doALoadOfStuff() {
        var windowWith = window.innerWidth;
        var advantage = $('.advantage-owl');

        if (windowWith < 768) {
            advantage.owlCarousel({
                center: true,
                items: 2,
                loop: true,
                dots: true,
                margin: 0,
            });
        } else {
            advantage.owlCarousel('destroy');
        }
    }

    doALoadOfStuff();

    $('.owl-news-view').owlCarousel({
        loop: true,
        dots: true,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: true,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    });

    var ceoOwl = $('.ceo-owl');

    ceoOwl.owlCarousel({
        loop: true,
        autoplay: true,
        margin: 10,
        autoplayTimeout: 4000,
        autoplayHoverPause: true,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 4
            }
        }
    });


    var securityOwl = $('#security-owl');

    securityOwl.owlCarousel({
        loop: false,
        autoplay: false,
        margin: 10,
        nav: true,
        dots: false,
        items: 1,
    });


    // Custom Navigation Events

    function owlCustumArrow(item) {
        $('.cust-next').click(function () {
            item.trigger('next.owl.carousel');
        });
        $('.cust-prev').click(function () {
            item.trigger('prev.owl.carousel');
        });
    }

    owlCustumArrow(suggestOwl);
    owlCustumArrow(ceoOwl);


    $('.house-next').click(function () {
        $(this).parent().next('.owl-carousel').trigger('next.owl.carousel');
    });
    $('.house-prev').click(function () {
        $(this).parent().next('.owl-carousel').trigger('prev.owl.carousel');
    });
    //End of owl init


    //start of tab Activation

    $('.info-card-li').on('click', {
        target: '.info-card-li',
        activeClass: 'info-tab-active'
    }, activeTab);

    $('.nav-pill').on('click', {
        target: '.nav-pill',
        activeClass: 'nav-custom-pill-active'
    }, activeTab);

    function activeTab(event) {

        $(event.data.target).each(function () {

            $(this).removeClass(event.data.activeClass);

        });

        $(this).addClass(event.data.activeClass);
    }

    //end of tab activation


    //Start toggle-menu

    //End toggle-menu


    // start short text toggle

    $('.short-text-nav').click(function () {

        toggleNav('.transfer-text', this);

    });

    $('.advantage').click(function () {

        $('.advantage').each(function () {
            $(this).removeClass('advantage-active');
        });
        $(this).addClass('advantage-active');

        $('.loan-nav-vtb').each(function () {
            $(this).fadeOut();
        });

        $('.loans-text-nav').each(function () {
            $(this).removeClass('text-light-blue');
        });


        $(this).find('a').addClass('text-light-blue');

        toggleNav('.loans-text', this);

    });

    // $('.advantage').mouseover(function () {
    //     $('.advantage').each(function () {
    //         $(this).removeClass('advantage-active');
    //     });
    //
    //     $(this).addClass('advantage-active');
    //
    //     $('.loan-nav-vtb').each(function () {
    //         $(this).fadeOut();
    //     });
    //
    //     $('.loans-text-nav').each(function () {
    //         $(this).removeClass('text-light-blue');
    //     });
    //
    //
    //     $(this).find('a').addClass('text-light-blue');
    //
    //     toggleNav('.loans-text', this);
    // });


    function toggleNav(nav, a) {

        var path = $(a).attr('data-nav');


        $(nav).each(function () {
            $(this).fadeOut(0);
        });


        $(path).fadeIn(0);

    }

    // end short text toggle

    //toggle border
    $('.short-text-nav').click(function () {

        $('.short-text-nav').each(function () {

            $(this).find('p').removeClass('b-main-blue')

        });

        var title = $(this).find('p').html();

        $('.money-transfer').html(title);

        $(this).find('p').addClass('b-main-blue');

    });


// Go to the next item
    $('.next').click(function () {
        headSlider.trigger('next.owl.carousel');
    });

// Go to the previous item
    $('.prev').click(function () {
        // With optional speed parameter
        // Parameters has to be in square bracket '[]'
        headSlider.trigger('prev.owl.carousel', [300]);
    });

    //left menu
    var Accordion = function (el, multiple) {
        this.el = el || {};
        this.multiple = multiple || false;

        // Variables privadas
        var links = this.el.find('.link');
        // Evento
        links.on('click', {el: this.el, multiple: this.multiple}, this.dropdown);
    };

    Accordion.prototype.dropdown = function (e) {
        var $el = e.data.el;
        $this = $(this),
            $next = $this.next();

        $next.finish();
        $next.slideToggle();

        $this.parent().toggleClass('open');

        if (!e.data.multiple) {
            $el.find('.submenu').not($next).slideUp().parent().removeClass('open');
        }
    };
    var accordion = new Accordion($('#accordion'), false);
    var accordionMobile = new Accordion($('#accordion-mobile'), false);
    /////////////categories tab/////////////////////

    // $('.private-hover').hover(function () {
    //         $('.private-nav-link').show()
    //     }, function () {
    //         $('.private-nav-link').hide()
    //     }
    // );

    var wrapperContent = document.getElementById('services-control');
    var secWrapperContent = document.getElementById('sec-services-control');

    function myFunction(x) {
        var serviceOwl = document.getElementById('contentOwl').innerText;
        serviceOwl = document.createRange().createContextualFragment(serviceOwl);

        var serviceProduct = document.getElementById('contentProduct').innerText;
        serviceProduct = document.createRange().createContextualFragment(serviceProduct);

        var secServiceOwl = document.getElementById('secContentOwl').innerText;
        secServiceOwl = document.createRange().createContextualFragment(secServiceOwl);

        var secServiceProduct = document.getElementById('secContentProduct').innerText;
        secServiceProduct = document.createRange().createContextualFragment(secServiceProduct);

        if (x.matches) {
            wrapperContent.innerHTML = '';
            wrapperContent.append(serviceOwl);

            secWrapperContent.innerHTML = '';
            secWrapperContent.append(secServiceOwl);


            topServiceOwl();
            secSericeOwl();
        } else {
            wrapperContent.innerHTML = '';
            wrapperContent.append(serviceProduct);

            secWrapperContent.innerHTML = '';
            secWrapperContent.append(secServiceProduct);
        }

    }

    var x = window.matchMedia("(max-width: 767px)");

    if (wrapperContent) {
        myFunction(x);
        x.addListener(myFunction);
    }

    controlD();
    controlAsk();
    mobileMenuControl();
    $('.currency-show').click(function () {
        document.querySelector('.home-currency').scrollIntoView({
            behavior: 'smooth',
            block: "center",
            inline: "nearest"
        }, function () {
            window.scrollBy(0, 100)
        });

    });

    // navigation control
    navControl();
});

function changeActiveCategory(a, id) {
    $categoryTab = $('.category-tab-btn');
    ////close all //////////
    $categoryTab.removeClass('active');
    $categoryTab.find('.cat-child').addClass('disabled');
    $('.credit-terms').addClass('disabled-term');
    ///////////set active //////////////////////
    $this = $(a).parents('.category-tab-btn');
    $this.find('.cat-child').removeClass('disabled');
    $this.addClass('active');
    if ($('#term' + id).length) {
        $('#term' + id).removeClass('disabled-term');
    } else {
        $child = $this.find('.has-term').first();
        id = $child.attr('data-id');
        $child.parents('.category-tab-btn').addClass('active');
        $('#term' + id).removeClass('disabled-term');
    }
}

//start of dropdown control

function controlD() {
    var size = $('#collapsibleNavbar').innerWidth();

    $('.drop-container').css({
        'width': '' + size / 1.5 + ''
    })
}


$(window).resize(function () {
    controlD();
    if ($(window).innerWidth() > 990) {
        $('.sidenav-wrapper').hide()
    }
    if ($(window).innerWidth() >= 991) {
        $('body').removeClass('overflowH');
        $('#nav-icon1').removeClass('open')
    }

    // navigation control
    navControl();

    //menu-width
    additionalCollapse();

    //mobile menu open control
    mobileMenuOpenControl();

    function mobileMenuOpenControl() {

        if (window.innerWidth > 1091) {
            if (document.getElementById('secondMenulist')) {
                let mobileDrops = document.getElementById('secondMenulist').querySelectorAll('.drop-init');
                if (mobileDrops.length > 0) {
                    for (let i = 0; i < mobileDrops.length; i++) {
                        mobileDrops[i].classList.remove('rot-open');
                        if (mobileDrops[i].parentElement.nextElementSibling) {
                            if (mobileDrops[i].parentElement.nextElementSibling.classList.contains('mobile-control-block')) {
                                mobileDrops[i].parentElement.nextElementSibling.classList.remove('mobile-control-block');
                            }
                        }
                    }
                    let secondMobileDrops = document.getElementById('secondMenuaction').querySelectorAll('.drop-init');
                    for (let i = 0; i < secondMobileDrops.length; i++) {
                        secondMobileDrops[i].classList.remove('rot-open');
                        secondMobileDrops[i].parentElement.nextElementSibling.classList.remove('mobile-control-block');
                    }
                }
                document.getElementById('dropdown9').classList.remove('mobile-control-block');
            }
        }
    }

});
//end of dropdown control


//start of contact input animation


$('.contact-input').click(function () {

    $(this).prev('span').addClass('active-placeholder');

});

$('.contact-input').blur(function () {

    if ($(this).val().length == 0) {

        $(this).prev('span').removeClass('active-placeholder');

    }

});

//end of contact input animation

//live chat control

function liveChat() {
    var rect = document.querySelector('footer').getBoundingClientRect();
    var controlHeight = document.querySelector('footer').offsetHeight;
    var scrollHeight = $(document).height();
    var scrollPosition = $(window).height() + $(window).scrollTop();
    if ((scrollHeight - scrollPosition) <= controlHeight / 2) {
        $('#toolbox-chat-iframe').css({
            'position': 'fixed',
            'transition': '0.4s',
            'bottom': controlHeight
        })
    } else {
        $('#toolbox-chat-iframe').css({
            'position': 'fixed',
            'transition': '0.4s',
            'bottom': '0'
        })
    }
}


//ask bank control
var globalAnotation = "";

function controlAsk() {
    var form = $('#private');
    var mForm = $('#moneyToyou');
    var cForm = $('#corp');

    $('.question-wrap').click(function () {
        if (document.querySelector('.ask-question-wrapper').querySelector('.nav')) {
            document.querySelector('.ask-question-wrapper').querySelector('.nav').style.opacity = '0';
        }
        window.location.hash = $(this).data('link');
        let form_data_name = this.getAttribute('data-name');
        document.getElementById(form_data_name).value = this.getAttribute('data-id');

        if ($(this).attr('data-persontype')) {
            let currentForm = $(this).attr('data-persontype');

            $('#ask-annotation').find('p').html("");
            $('#ask-annotation').css({'display': 'none'});

            var title = $(this).find('p').html();

            $('.ask-form-title').html(title);
            $(currentForm).fadeIn();
            $(this).closest('.tab-content').fadeOut();
            window.scroll({
                top: 0,
                behavior: 'smooth'
            });

        } else {
            $('#form_emailToDepartment').val($(this).find('input').eq(0).val());
            globalAnotation = $(this).find('input').eq(1).val();


            $('#ask-annotation').find('p').html("");
            $('#ask-annotation').css({'display': 'none'});

            if (globalAnotation.length > 0) {
                $('#ask-annotation').find('p').html(globalAnotation);
                $('#ask-annotation').css({
                    'display': 'block'
                })
            }

            var title = $(this).find('p').html();

            $('.ask-form-title').html(title);
            form.fadeIn();
            $(this).closest('.tab-content').fadeOut();
            window.scroll({
                top: 0,
                behavior: 'smooth'
            });
        }
    });

    $('.ask-link,.ask-return').click(function () {
        if (document.querySelector('.ask-question-wrapper').querySelector('.nav')) {
            document.querySelector('.ask-question-wrapper').querySelector('.nav').style.opacity = '1';
        }

        window.location.hash = '';
        var allForms = $('.ask-form');
        allForms.fadeOut();
        $('.ask-tab-content').fadeIn();
        if (this.closest('form')) {
            var errs = document.getElementsByClassName('form-error-text');
            for (let i = 0; i < errs.length; i++) {
                errs[i].innerHTML = '';
            }
            var inps = this.closest('form').querySelectorAll('input[type="text"], select');
            if (inps.length > 0) {
                for (let i = 0; i < inps.length; i++) {
                    inps[i].value = '';
                }
                $('select').formSelect();
            }
        }
    })
}

function askFileType(This) {
    let files = This.files;
    let cargo = This.nextSibling;

    for (let i = 0; i < files.length; i++) {

        let fileName = files[i].name;

        cargo.value += fileName + ',';

        let fullName = fileName;
        if (files[i].name.length > 10) {

            fileName = fileName.split('.');
            let fileExt = fileName[1];
            fileName = fileName[0].slice(0, 10);
            $(This).prev().append(`<span><i class="fa fa-file"></i>${fileName}...${fileExt},<sup data-name='${fullName}' class="fa fa-close" onclick="removeFile(this)"></sup></span>`)
        } else {
            $(This).prev().append(`<span><i class="fa fa-file"></i>${fileName},<sup data-name='${fullName}' class="fa fa-close" onclick="removeFile(this)"></sup></span>`)
        }

    }
}

function removeFile(This) {
    let item = This.getAttribute('data-name');
    let names = $('#fileCargo').val();
    let newNames = names.replace(item + ',', '');
    $('#fileCargo').val(newNames);
    $(This).parent().remove();
}

//select control

function selectControl() {

    $('.report-select').change(function () {

        var This = $(this);
        var stepSelect = '.' + This.attr('data-step');

        $(stepSelect).prop("disabled", false);
        $('select').formSelect();

    })

}

selectControl();

var local_lang = document.getElementById('lang');

$('.report-year').change(function () {
    let this_val = this.value;
    let reportText = document.getElementById('report-text');
    let lang = local_lang.value;
    $.ajax(`/${lang}/get-report`, {
        type: 'POST',
        dataType: 'json',
        data: {id: this_val},
        success: function (r) {
            reportText.innerHTML = '';
            if (r.text) {
                reportText.innerHTML = r.text + '<hr  style="border-color: #009fdf;">';
            } else {
                reportText.innerHTML = '<hr  style="border-color: #009fdf;">';
            }
        }
    });
});


function emailCondition(This) {
    let emailCondition = This.value;
    let emailTarget = This.getAttribute('data-email');
    document.getElementById(emailTarget).disabled = (emailCondition === 'noAnswer') ? true : '';
    document.getElementById(emailTarget).required = (emailCondition === 'noAnswer') ? '' : true;
}

function valueToggler(This) {
    This.value = (This.checked) ? "11" : "1"
}


function logSubmit(event) {
    let mail = $('#ring_preference_request_form_email').val();
    let phone = $('#ring_preference_request_form_phone').val();
    var numb = phone.match(/\d/g);
    $('#ring_preference_request_form_email').css('border-color', '#989eb3');
    $('#ring_preference_request_form_phone').css('border-color', '#989eb3');
    numb = numb.join("");
    if (!validateEmail(mail)) {
        event.preventDefault();
        $('#ring_preference_request_form_email').focus();
        $('#ring_preference_request_form_email').css('border-color', '#ed1c24');
    } else if (numb.length !== 11) {
        event.preventDefault();
        $('#ring_preference_request_form_phone').focus();
        $('#ring_preference_request_form_phone').css('border-color', '#ed1c24');
    }
}

if (document.getElementById('private13')) {
    const form = document.getElementById('private13').querySelector('form');
    form.addEventListener('submit', logSubmit);
}


function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


function additionalCollapse() {
    if (window.innerWidth >= 1092) {
        var mainWidth = document.querySelector('.first-menu').offsetWidth;
        var calWidth = mainWidth - 273.55;
        var element = document.querySelectorAll('.additional-dropdown-content');
        for (var i = 0; i < element.length; i++) {
            element[i].style.width = calWidth + 'px';
            element[i].style.left = "-" + (calWidth - 2) + 'px';
        }
    }
}

additionalCollapse();

$('.currency-toggle').click(function () {
    $('.currency-toggle').each(function () {
        $(this).removeClass('active')
    });
    $(this).addClass('active');
    var item = $(this).data('href');
    $('.footer-currency').fadeOut(100);
    $(item).fadeIn(200);
});


function navControl() {
    if (document.getElementById('secondMenulist')) {
        let navList = document.getElementById('secondMenulist');

        let liElem = navList.querySelectorAll('.nav-item');
        let showElement;

        for (let i = 0; i < liElem.length; i++) {
            if (liElem[i].getBoundingClientRect().top > 83.5) {
                showElement = liElem[i].getAttribute('data-elemvisibel');
                document.getElementById("" + showElement).classList.remove('hideMeElem');
            } else {
                showElement = liElem[i].getAttribute('data-elemvisibel');
                document.getElementById("" + showElement).classList.add('hideMeElem');
            }

        }
    }
}

//mobile menu control

function mobileMenuControl() {
    if (document.getElementById('secondMenulist')) {
        let mobileDrops = document.getElementById('secondMenulist').querySelectorAll('.drop-init');
        mobileDrops.forEach(item => {
            item.addEventListener('click', function (event) {
                if (window.innerWidth <= 1091) {
                    this.parentNode.addEventListener('click', event.preventDefault());
                    for (let i = 0; i < mobileDrops.length; i++) {
                        if (mobileDrops[i] !== this) {
                            mobileDrops[i].classList.remove('rot-open');
                            mobileDrops[i].parentElement.nextElementSibling.classList.remove('mobile-control-block')
                        }
                    }
                    this.classList.toggle('rot-open');
                    this.parentElement.nextElementSibling.classList.toggle('mobile-control-block');
                    document.getElementById('dropdown9').classList.remove('mobile-control-block');
                }

            })
        });
    }


    //    secondMenuaction
    if (document.getElementById('secondMenuaction')) {
        let secondMobileDrops = document.getElementById('secondMenuaction').querySelectorAll('.drop-init');
        secondMobileDrops.forEach(item => {
            item.addEventListener('click', function (event) {
                if (window.innerWidth <= 1091) {
                    for (let i = 0; i < secondMobileDrops.length; i++) {
                        if (secondMobileDrops[i] !== this) {
                            secondMobileDrops[i].classList.remove('rot-open');
                            secondMobileDrops[i].parentElement.nextElementSibling.classList.remove('mobile-control-block')
                        }
                    }
                    this.classList.toggle('rot-open');
                    this.parentElement.nextElementSibling.classList.toggle('mobile-control-block');
                    this.parentNode.addEventListener('click', event.preventDefault())
                }
            })
        });
    }

    if (document.querySelector('.more-link')) {
        let actionPart = document.querySelector('.more-link');
        actionPart.addEventListener("click", function () {
            if (window.innerWidth <= 1091) {
                document.getElementById('dropdown9').classList.toggle('mobile-control-block');
                for (let i = 0; i < mobileDrops.length; i++) {
                    mobileDrops[i].classList.remove('rot-open');
                    mobileDrops[i].parentElement.nextElementSibling.classList.remove('mobile-control-block')
                }
            }
        })
    }
}

//ask question tab-init
function askMeTab() {
    let link = window.location.pathname.split('/');
    if (link[link.length - 1] === 'ask-question') {
        let item = window.location.hash;
        item = item.replace('#', '');
        item = item.split('-');
        if (
            item.length === 2
            &&
            document.getElementById(item[0])
            &&
            document.getElementById(item[1])
        ) {
            document.getElementById(item[0]).click();
            document.getElementById(item[1]).click();
        }
    }
}


function toggleCurrency() {
    let checker = document.getElementById('transferCurrency');
    if (checker) {
        document.getElementById('transferCurrency').addEventListener('change', function (e) {
            let currency = this.value;
            let resCurrency = document.getElementById('reviveCurrency');
            for (let i = 0; i < resCurrency.options.length; i++) {
                if (resCurrency.options[i].value !== 'AMD' && resCurrency.options[i].value !== currency) {
                    resCurrency.options[i].disabled = true;
                } else {
                    resCurrency.options[i].removeAttribute('disabled');
                    if (resCurrency.options[i].value === currency) {
                        resCurrency.options[i].setAttribute('selected', 'selected')
                    }
                }
            }
            resCurrency.closest('.select-wrapper').nextSibling.style.display = 'none';
            resCurrency.removeAttribute('disabled');
            $('#reviveCurrency').formSelect();
        })
    }
}

toggleCurrency();


$(".card-number").inputmask({
    "mask": " 9999-9999-9999-9999",
    oncomplete: function () {
        if (this.closest('.form-group').querySelector('.form-error-text')) {
            this.closest('.form-group').querySelector('.form-error-text').remove();
        }
    }
});
$(".bank-number").inputmask({
    "mask": "1609-9999-9999-9999",
    oncomplete: function () {
        if (this.closest('.form-group').querySelector('.form-error-text')) {
            this.closest('.form-group').querySelector('.form-error-text').remove();
        }
    }
});

$("#transfer_orderAmount").inputmask({"mask": "9", "repeat": 10, "greedy": false});

$(".num-format").inputmask({"mask": "9", "repeat": 10, "greedy": false});

$("#transfer_transferNumber").inputmask({
    "mask": '*', "repeat": 16, definitions: {
        '*': {
            validator: "[0-9A-Za-z]",
            casing: "lower"
        }
    }
});

$('.pas-length').inputmask({
    "mask": '*', "repeat": 9, definitions: {
        '*': {
            validator: "[0-9A-Za-z]",
        }
    },

});
$('.pas-id-length').inputmask({
    "mask": '*', "repeat": 16, definitions: {
        '*': {
            validator: "[0-9A-Za-z]",
        }
    },
    oncomplete: function () {
        if (this.closest('.form-group').querySelector('.form-error-text')) {
            this.closest('.form-group').querySelector('.form-error-text').remove();
        }
    }
});


$('.mask-date').inputmask("99.99.9999",
    {
        alias: "datetime",
        inputFormat: "mm/dd/yyyy",
        'autoUnmask': true,
    }
);


$('.mask-time').inputmask(
    {
        alias: "datetime",
        inputFormat: "HH:MM",
        placeholder: "__:__",
        "isComplete": function () {
        }

    }
);

$(".pos_phone_number").inputmask({
    "mask": "(+374) 99-99-99-99",
    "placeholder": "__",
    oncomplete: function () {
        if (this.closest('.form-group')) {
            if (this.closest('.form-group').querySelector('.form-error-text')) {
                this.closest('.form-group').querySelector('.form-error-text').remove();
            }
        }

    }
    // showMaskOnHover: false,
    // showMaskOnFocus: false,
});

$(".card_number_phisical").inputmask({
    "mask": "9999-99XX-XXXX-9999",
    "placeholder": "__",
});


//
$('.alphabetical').inputmask({
    "mask": 'a', "repeat": 60, definitions: {
        'a': {
            validator: "[aA-zZ-ա-Ա-ֆ-Ֆ-և-և]",
        },
    },
    // onKeyValidation: function(key, result){
    // var errEl = this.closest('.form-group').querySelector('.form-error-text');
    // if (!result){
    //    errEl.innerHTML = 'Only string';
    // }else{
    //    errEl.innerHTML = '';
    // }
    // }
});

$(".email").inputmask({alias: "email", greedy: false});

/*$('.email').inputmask({
    mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
    greedy: false,
    placeholder: "yourname@example.com",
    showMaskOnHover: false,
    showMaskOnFocus: false,
    onBeforePaste: function (pastedValue, opts) {
        pastedValue = pastedValue.toLowerCase();
        return pastedValue.replace("mailto:", "");
    },
    definitions: {
        '*': {
            validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
        }
    },
    oncomplete: function () {
        if (this.closest('.form-group').querySelector('.form-error-text')) {
            this.closest('.form-group').querySelector('.form-error-text').remove();
        }
    }
});*/

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


let map_opener_individuals = document.querySelectorAll('.map-opener-individuals');
var map, map2;

function initPartnierMap() {
    {
        var myLatLng = {lat: 40.1669807, lng: 44.4627931};
        var options1 =
            {
                zoom: 8,
                center: myLatLng,
                styles: [
                    {
                        "stylers": [
                            {
                                "saturation": -100
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#0099dd"
                            }
                        ]
                    },
                    {
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#aadd55"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {}
                ]
            };

        map = new google.maps.Map(document.getElementById("individuals-map-container"), options1);

        map2 = new google.maps.Map(document.getElementById("business-map-container"), options1);

        let indLocations = document.getElementById('indLocation');
        let busLocations = document.getElementById('busLocation');

        JSON.parse(indLocations.value).forEach(function (item) {
            let lat = Number(item.lat);
            let lng = Number(item.lng);

            var myLatLng = {lat: lat, lng: lng};
            var marker = new google.maps.Marker({
                position: myLatLng,
                map: map,
                icon: '/map/point.png'
            });
        })

        JSON.parse(busLocations.value).forEach(function (item) {
            let lat = Number(item.lat);
            let lng = Number(item.lng);

            var myLatLng = {lat: lat, lng: lng};
            var marker2 = new google.maps.Marker({
                position: myLatLng,
                map: map2,
                icon: '/map/point.png'
            });
        })
    }
}

if (map_opener_individuals) {
    map_opener_individuals.forEach(function (item) {
        item.onclick = function () {
            let lat = this.getAttribute('data-lat');
            let lng = this.getAttribute('data-lng');
            markPartners(lat, lng);
            document.getElementById('map-individuals').click();
        }
    });
}

function markPartners(lat, lng) {
    var myLatLng = {lat: Number(lat), lng: Number(lng)};
    map = new google.maps.Map(document.getElementById('individuals-map-container'), {
        zoom: 12,
        center: myLatLng,
        styles: [
            {
                "stylers": [
                    {
                        "saturation": -100
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#0099dd"
                    }
                ]
            },
            {
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#aadd55"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {}
        ]
    });

    var marker = new google.maps.Marker({
        position: myLatLng,
        bounds: true,
        map: map,
        icon: '/map/point.png'
    });
}

let map_opener_business = document.querySelectorAll('.map-opener-business');
if (map_opener_business) {
    map_opener_business.forEach(function (item) {
        item.onclick = function () {
            let lat = this.getAttribute('data-lat');
            let lng = this.getAttribute('data-lng');
            markPartnersBusiness(lat, lng);
            document.getElementById('map-business').click();
        }
    });
}

function markPartnersBusiness(lat, lng) {
    var myLatLng = {lat: Number(lat), lng: Number(lng)};
    map = new google.maps.Map(document.getElementById('business-map-container'), {
        zoom: 12,
        center: myLatLng,
        styles: [
            {
                "stylers": [
                    {
                        "saturation": -100
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#0099dd"
                    }
                ]
            },
            {
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#aadd55"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {}
        ]
    });
    var marker = new google.maps.Marker({
        position: myLatLng,
        bounds: true,
        map: map,
        icon: '/map/point.png'
    });
}

//Ask Question Validation

function validateAskForm(formSelector) {
    let selectedElem = document.querySelector(formSelector);
    let validationChecker = false;
    if (selectedElem) {
        let selectedElemForm = selectedElem.querySelector('form');
        selectedElemForm.reset();
        let checker = document.querySelector('#transfer_isAgree');
        checker.addEventListener('click', function () {
            let inputValid = [
                'transfer_transferNumber',
                'transfer_orderAmount',
                'transferCurrency',
                'transfer_cardNumber',
                'reviveCurrency',
                'transfer_phone',
                // 'transfer_form_acceptCondition'
            ];
            for (let i = 0; i < inputValid.length; i++) {
                if (document.querySelector('#' + inputValid[i]).value) {
                    validationChecker = true;
                } else {
                    validationChecker = false;
                    break;
                }
            }
            if (validationChecker === true) {
                document.querySelector('#transfer_submit').disabled = false;
                document.querySelector('#transfer_isAgree').checked = true;
            } else {
                document.querySelector('#transfer_isAgree').checked = false;
                // document.querySelector('#transfer_form_acceptCondition').removeAttribute('checked');
                document.querySelector('#transfer_submit').disabled = true;
            }

        })
    }
}

validateAskForm('#private6');

function personTypeToggler(e, This) {

    let elems = document.getElementById('personDropDown');
    let nav = document.querySelector('.nav-content-wrap');
    let head = document.querySelector('.head-divider');
    let instances;
    if (e.target.classList.contains('prefAction')) {
        instances = M.Dropdown.init(elems, {
            hover: false,
            constrainWidth: false,
            coverTrigger: false,
        },);
        instances.close();
        window.location.href = This.href;
    } else {
        let instance = M.Dropdown.getInstance(elems);
        instance.options.coverTrigger = false;
        instance.options.onOpenStart = function () {
            nav.classList.add('s-cor');

            instance.el.classList.add('a-rotate');
        };
        instance.options.onCloseStart = function () {
            nav.classList.remove('s-cor');

            instance.el.classList.remove('a-rotate');
        };
        document.querySelectorAll('.main-nav-item').forEach(item => {
            item.addEventListener('mouseover', function () {
                nav.classList.add('s-cor');
                instance.close();
            });
        });
        return true
    }
}

function linkTabControl(activator) {
    const pathName = window.location.pathname.split('/');
    const conditionalActivator = document.getElementById(activator);
    const pathname = window.location.hash.split('-');
    if (conditionalActivator ? true : false && window.location.hash) {
        let tabToNavigate = pathname[0];
        if (tabToNavigate.length > 1) {
            tabToNavigate = tabToNavigate.split('#');
            if (document.getElementById(tabToNavigate[1])) {
                document.getElementById(tabToNavigate[1]).click();
            }
            let tabItemContainer = (tabToNavigate[1] === 'corpType') ? 'corporateClients' : 'privatClients';
            let targetElements = document.getElementById(tabItemContainer).querySelectorAll('.question-wrap');
            for (let i = 0; i < targetElements.length; i++) {
                if (targetElements[i].getAttribute('data-id') === pathname[1]) {
                    setTimeout(function () {
                        targetElements[i].click();
                    }, 200)
                }
            }
        }
    }
    if (pathName[pathName.length - 2] === 'online-request') {
        let targetElements = document.querySelector('.ask-tab-content').querySelectorAll('.question-wrap');
        for (let i = 0; i < targetElements.length; i++) {
            if (targetElements[i].getAttribute('data-id') === pathname[1]) {
                setTimeout(function () {
                    targetElements[i].click();
                }, 200)
            }
        }
    }

}

linkTabControl('privType');

function IndividualValidation() {
    const pathName = window.location.pathname.split('/');
    if (pathName[pathName.length - 2] === 'online-request' || pathName[pathName.length - 2] === 'ask-question') {
        let mainForms = document.querySelector('.main-content-wrapper').querySelectorAll('form');
        for (let i = 0; i < mainForms.length; i++) {
            if (mainForms[i].id === '') {
                let inputs = mainForms[i].querySelectorAll('input[type="checkbox"]');
                for (let j = 0; j < inputs.length; j++) {
                    inputs[j].addEventListener('change', function () {
                        let primaryCondition = false;
                        let requairedInputs = this.closest('form').querySelectorAll('input[required]');
                        let actionButton = this.closest('form').querySelector('button');
                        for (let k = 0; k < requairedInputs.length; k++) {
                            if (requairedInputs[k].value.length == 0) {
                                primaryCondition = true;
                                actionButton.disabled = primaryCondition;
                                return true;
                            }
                        }
                        actionButton.disabled = primaryCondition;
                    })
                }
            }
        }
    }
}

IndividualValidation();


function telTypeToggler(This, e) {
    e.preventDefault();
    let inputToChange = '#' + This.closest('.tel-type').querySelector('input').id;
    $(inputToChange).val('');
    if (This.getAttribute('data-teltype') === 'arm') {
        $(inputToChange).attr('placeholder', '(+374) __-__-__-__');
        $(inputToChange).inputmask({"mask": "(+374) 99-99-99-99"});
    } else {
        $(inputToChange).removeAttr('placeholder');
        $(inputToChange).inputmask('remove');
        $(inputToChange).inputmask({
            "mask": "+ 999999999999999",
            "placeholder": ''
        });
    }
}

function changePhoneFormat(This) {
    var inp = This.closest('.tel-type').querySelector('input');
    var format = This.getAttribute('data-format');
    var plc = This.getAttribute('data-plc');

    $(inp).attr('placeholder', plc);
    $(inp).inputmask({"mask": format});
};

function removeMaskInput(This) {
    var inp = This.closest('.tel-type').querySelector('input');
    $(inp).removeAttr('placeholder');
    $(inp).inputmask('remove');
    $(inp).inputmask({
        "mask": "+ 999999999999999",
        "placeholder": ''
    });
};

$('.birthday-picker').datepicker({
    // autoPick: true,
    format: 'dd.mm.YYYY',
    days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    daysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    daysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    weekStart: 1,
    startView: 0,
    yearFirst: false,
    yearSuffix: '',
});

$('.mask-date').datepicker({
    // autoPick: true,
    format: 'dd.mm.YYYY',
    days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    daysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    daysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    weekStart: 1,
    startView: 0,
    yearFirst: false,
    yearSuffix: '',
    startDate: formatDate(),
    filter: function (date) {
        if (date.getDay() === 0 || date.getDay() === 6) {
            return false; // Disable all Sundays, but still leave months/years, whose first day is a Sunday, enabled.
        }
    }
});

function formatDate() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
}

function datePickerActivation(This) {
    let selEl = '#' + This.parentNode.previousSibling.id;
    setTimeout(function () {
        $(selEl).datepicker('show');
    }, 10)
}

let wrap_adv = document.querySelectorAll('.wrapper-adv');

if (wrap_adv) {
    for (let i = 0; i < wrap_adv.length; i++) {
        let txt = wrap_adv[i];
        let arr = txt.innerHTML.split(' ');
        if (arr.length <= 2) {
            arr[0] = arr[0] + '<br>';
            txt.innerHTML = arr.join(' ')
        } else {
            arr[1] = arr[1] + '<br>';
            txt.innerHTML = arr.join(' ')
        }
    }
}


let referenceType = document.querySelectorAll('.referenceType');

if (referenceType) {

    let changingInput = document.querySelectorAll('.changingInput');
    for (let i = 0; i < referenceType.length; i++) {

        referenceType[i].onchange = function () {

            let dataName = this.options[this.selectedIndex].getAttribute('data-name');

            changingInput.forEach(function (item) {

                if (item.style.display !== 'none') {
                    item.style.display = 'none';
                }

            });
            (dataName !== null) ? document.getElementById('' + dataName + '').style.display = 'block' : '';

        }
    }
}

function mainBranchInit() {
    let mainBranch = [{
        address: "г.Ереван, ул. Московян 35 71/1",
        category: "branches",
        city: null,
        district: "9",
        hasTerminal: "false",
        icon: "/map/point.png",
        lat: "40.1878259",
        lng: "44.5098624",
        region: "1",
        table_city: "Ереван",
        table_district: "Кентрон",
        table_phone: "(+37410) 60 60 90</br>внут. 71071#",
        table_workingHours: "Пн-Пт",
        title: "Привилегия",
        workingHours: "6"
    }];
    localStorage.setItem('mapPosition', JSON.stringify(mainBranch))
}


function formReset(This) {
    This.parentNode.querySelector('form').reset();
}


let house_feature = document.querySelectorAll('input.house-feature');
if (house_feature) {

    let house_fool = document.getElementById('house_fool');
    let house_houseFloor = document.getElementById('house_houseFloor');
    let house_houseRoomCount_select = document.getElementById('house_houseRoomCount_select');
    let house_houseRoomCount = document.getElementById('house_houseRoomCount');

    for (let i = 0; i < house_feature.length; i++) {
        if (house_feature[i].checked === true) {
            let data_id = house_feature[i].getAttribute('data-id');
            disableSelect(data_id)
        }
        house_feature[i].onchange = function () {
            let data_id = this.getAttribute('data-id');
            disableSelect(data_id);
            if (house_feature[i].checked === true) {
                house_feature.forEach(item => {
                    if (item.checked && item !== this) {
                        item.checked = false;
                    }
                });
            } else {
                house_fool.disabled = false;
                house_houseRoomCount_select.disabled = false;
            }
            $('select').formSelect()
        }

    }

    function disableSelect(data_id) {
        // 1 = Регион, Количество Комнат
        // 3,4 = Регион
        // 5,6 =Регион, Этаж
        if (data_id === '1') {
            house_fool.selectedIndex = 0;
            house_fool.disabled = true;
            house_houseFloor.value = '';
            house_houseRoomCount_select.disabled = false;
        } else if (data_id === '3' || data_id === '4') {
            house_fool.disabled = true;
            house_fool.selectedIndex = 0;
            house_houseFloor.value = '';
            house_houseRoomCount_select.selectedIndex = 0;
            house_houseRoomCount_select.disabled = true;
            house_houseRoomCount.value = '';
        } else if (data_id === '5' || data_id === '6') {
            house_houseRoomCount_select.selectedIndex = 0;
            house_houseRoomCount_select.disabled = true;
            house_houseRoomCount.value = '';
            house_fool.disabled = false;
        } else {
            house_fool.disabled = false;
            house_houseRoomCount_select.disabled = false;
        }
    }
}

$('.selectPaymentMethod').change(function () {
    let data_name = $(this).data('name');
    let
        property_request_purchasePrice = document.getElementById('' + data_name + '');
    let prContent = document.querySelector('.' + data_name + '');
    if ($(this).val() === '1') {
        prContent.style.display = 'block';
        property_request_purchasePrice.required = true;
        property_request_purchasePrice.readonly = false;
        property_request_purchasePrice.disabled = false;
    } else {
        prContent.style.display = 'none';
        property_request_purchasePrice.readonly = true;
        property_request_purchasePrice.required = false;
        property_request_purchasePrice.disabled = true;
    }
});

let otherInfoSource = document.getElementById('corp_credit_request_informationSource');


if (otherInfoSource) {
    let change_place = document.getElementById('change_place');
    let corp_credit_request_place = document.getElementById('corp_credit_request_place');
    change_place.onchange = function () {
        corp_credit_request_place.value = this.value;
    };

    otherInfoSource.onchange = function () {
        if (this.value === '20') {
            this.required = false;
            document.querySelector('.other-content').style.display = 'block';
            document.querySelector('.other-content').querySelector('input').value = '';
        } else {
            this.required = true;
            document.querySelector('.other-content').style.display = 'none';
            document.querySelector('.other-content').querySelector('input').value = '';
        }
    }
}

function customCollapseInit() {
    let customCollapse = document.querySelectorAll('.custom-collapse-init');
    if (customCollapse.length > 0) {
        customCollapse.forEach(item => {
            item.addEventListener('click', function () {
                this.parentNode.classList.toggle('cust-open');
            })
        })
    }
}

function closeModalWindow(modalSelector) {
    let thisModal = M.Modal.getInstance($('#onlineRequestHouse'));
    thisModal.close();
}

if (document.getElementById('fSubmit')) {
    document.getElementById('fSubmit').addEventListener('click', function (e) {
        e.preventDefault();
        var vl = document.getElementById('ord_call_form_phoneNumber').value;
        var patt = '_';
        var res = vl.match(patt);
        if (res === null) {
            document.getElementById('ord_call_form_submit').click();
        }
    });
}

if (document.getElementById('order_call_houses_page')) {
    document.getElementById('order_call_houses_page').addEventListener('click', function (e) {
        e.preventDefault();
        var vl = document.getElementById('order_call_phone').value;
        var patt = '_';
        var res = vl.match(patt);
        if (res === null) {
            document.getElementById('order_call_send').click();
        }
    });
}

if (document.getElementById('agent_question_btn')) {
    document.getElementById('agent_question_btn').addEventListener('click', function (e) {
        e.preventDefault();
        var vl = document.getElementById('asq_question_agency_phoneNumber').value;
        var name = document.getElementById('asq_question_agency_name').value;
        var patt = '_';
        var res = vl.match(patt);

        if (name) {

            if (vl && res === null) {
                var email = document.getElementById('asq_question_agency_email').value;
                if (validateEmail(email)) {
                    var text = document.getElementById('asq_question_agency_text').value;
                    if (text) {
                        document.getElementById('asq_question_agency_send').click();
                    } else {
                        document.getElementById('asq_question_agency_text').focus();
                    }
                } else {
                    document.getElementById('asq_question_agency_email').focus();
                }
            } else {
                document.getElementById('asq_question_agency_phoneNumber').focus();
            }
        } else {
            document.getElementById('asq_question_agency_name').focus();
        }

    });

}


window.onhashchange = function () {
    openCurrentTab();
};

function openCurrentTab() {
    let currentHash = window.location.hash.replace(/#/g, '');
    let stoppedTop = document.getElementById(currentHash);
    try {
        if (stoppedTop) {
            window.scroll({
                top: stoppedTop.offsetTop - 35,
                left: 0,
                behavior: 'smooth'
            });
            stoppedTop.click();
        }
    } catch (err) {
    }
}


function extractHref() {
    localStorage.setItem('showExtract', 1)
}


var owlLoginPage = $('.owl-login-page');

owlLoginPage.owlCarousel({
    loop: true,
    autoplay: true,
    margin: 0,
    nav: false,
    dots: false,
    items: 1,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    smartSpeed: 2000
});


function selectLoanType(This) {
    var selectedOption = This.options[This.selectedIndex];
    console.log(selectedOption)
    var selectShopWrapper = document.getElementById('shop-select-wrapper');
    var selectShop = document.getElementById('shop_address');
    var preferredFieldWrapper = document.getElementById('preferred-amount-wrapper');
    var preferredField = document.getElementById('preferred-amount')

    var isRegisteredWrapper = document.getElementById('is-registered-wrapper');
    var isRegistered = document.getElementById('is_registered')

    var cardNumberWrapper = document.getElementById('card-number-wrapper');
    var cardNumber = document.getElementById('card_number');

    var passportPhotoWrapper = document.getElementById('passport_photo_wrapper');
    var passportPhoto = document.getElementById('passport_photo');

    var wrapperCheckLast = document.getElementById('wrapper-checkbox-last');
    var checkLast = document.getElementById('account_reference_isAgree_3');
    var href_checkbox_one = document.querySelector('.href-check-one');
    var href_checkbox_two = document.querySelector('.href-check-two');

    if (selectedOption.classList.contains('current_select')) {
        href_checkbox_one.classList.add('display-none')
        href_checkbox_two.classList.remove('display-none')
        selectShopWrapper.classList.add('display-none')
        selectShop.classList.remove('required-fizikal')
        selectShop.value = '';

        preferredFieldWrapper.classList.remove('display-none');
        preferredField.classList.add('required-fizikal');

        isRegisteredWrapper.classList.remove('display-none');
        isRegistered.classList.add('required-fizikal');
        $(isRegistered).formSelect();

        cardNumberWrapper.classList.remove('display-none');

        passportPhotoWrapper.classList.remove('display-none');
        passportPhoto.classList.add('required-fizikal');

        wrapperCheckLast.classList.remove('display-none');
        checkLast.checked = false;
    } else {
        href_checkbox_one.classList.remove('display-none')
        href_checkbox_two.classList.add('display-none')
        $(selectShop).formSelect();
        selectShopWrapper.classList.remove('display-none')
        selectShop.classList.add('required-fizikal')

        preferredFieldWrapper.classList.add('display-none');
        preferredField.classList.remove('required-fizikal');
        preferredField.value = '';

        isRegisteredWrapper.classList.add('display-none');
        isRegistered.classList.remove('required-fizikal');
        isRegistered.value = '';

        cardNumberWrapper.classList.add('display-none');
        cardNumber.value = '';

        passportPhotoWrapper.classList.add('display-none');
        passportPhoto.classList.remove('required-fizikal');
        passportPhoto.value = '';

        if (passportPhotoWrapper.querySelector('.uploaded-files > span')) {
            passportPhotoWrapper.querySelector('.uploaded-files > span').remove();
        }

        wrapperCheckLast.classList.add('display-none');
        checkLast.checked = true;
    }
}

function cardNumberPhysicalRequest(This) {
    var errorWrapper = document.getElementById('error_card_number');
    var myStorage = window.localStorage;
    var numbers = JSON.parse(myStorage.getItem('numbers'));

    var cardNumber = This.value;
    // cardNumber = cardNumber.slice(7);
    var newstring = cardNumber.replace(/-/g, '');
    newstring = newstring.replace(/X/g, '');
    newstring = newstring.replace(/_/g, '');

    if (newstring.length >= 6) {
        newstring = newstring.slice(0, 6);
        console.log(newstring)
        if (!numbers.includes(newstring)) {
            errorWrapper.classList.remove('display-none');
        } else {
            errorWrapper.classList.add('display-none');
        }
    }
}


function changePreferredAmount(This) {
    var wrapperMinError = document.getElementById('min_value_error');
    var wrapperMaxError = document.getElementById('max_value_error');
    var amount = (This.value.split(',').join('')).split(' AMD').join('');
    amount = Number(amount);
    if (amount < 100000) {
        wrapperMinError.classList.remove('display-none')
    } else {
        wrapperMinError.classList.add('display-none')
    }

    if (amount > 4000000) {
        wrapperMaxError.classList.remove('display-none')
    } else {
        wrapperMaxError.classList.add('display-none')
    }

}


function approvedRegisteredWorking(This) {
    if (This.value === '0') {
        document.querySelector('#approved-working').classList.remove('display-none')
        document.querySelector('.fizical-btn').classList.add('disabled-btn')
    } else {
        document.querySelector('#approved-working').classList.add('display-none')
        document.querySelector('.fizical-btn').classList.remove('disabled-btn')
    }
}

function changeAge(This) {
    var age = This.value;

    var cardTypes = document.querySelectorAll('.new_card_types');

    for (let i = 0; i < cardTypes.length; i++) {
        cardTypes[i].checked = false;
        cardTypes[i].setAttribute('disabled', 'disabled')
    }

    if (age >= 6 && age <= 9) {
        cardTypes[0].removeAttribute('disabled');
        cardTypes[0].checked = true;
    }
    if (age >= 10 && age <= 12) {
        cardTypes[0].removeAttribute('disabled');
        cardTypes[1].removeAttribute('disabled');
    }
    if (age >= 13 && age <= 18) {
        cardTypes[1].removeAttribute('disabled');
        cardTypes[2].removeAttribute('disabled');
    }
    if (age >= 19 && age <= 24) {
        cardTypes[2].removeAttribute('disabled');
    }


    checkCardTypes();
}

function changeCardType(This) {
    checkCardTypes();
}

function checkCardTypes() {
    var cardTypes = document.querySelectorAll('.new_card_types');
    var educationNameField = document.querySelector('.education_name_field');

    for (let i = 0; i < cardTypes.length; i++) {
        if (cardTypes[i].checked && cardTypes[i].value === 'iSTUDENT') {
            educationNameField.setAttribute('required', 'required');
            educationNameField.closest('.col-lg-6').classList.remove('display-none');
        } else {
            educationNameField.value = '';
            educationNameField.removeAttribute('required')
            educationNameField.closest('.col-lg-6').classList.add('display-none');
        }
    }
}

